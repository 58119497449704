import {
  UserOutlined,
  //ImportOutlined /* , TranslationOutlined */,
  QrcodeOutlined,
  SettingOutlined,
  BankOutlined,
  FieldTimeOutlined,
  TableOutlined,
  FilePptOutlined,
  FileTextOutlined,
  BarcodeOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import Users from '../pages/users/Users';
import EditUser from '../pages/users/EditUser';
import ViewUser from '../pages/users/ViewUser';
import Material from '../pages/material/Material';
import EditMaterial from '../pages/material/EditMaterial';
import ExcessRoll from '../pages/material/ExcessRoll';
import EditRoll from '../pages/material/EditRoll';
import ViewRoll from '../pages/material/ViewRoll';
import Machines from '../pages/machines/Machines';
import EditMachine from '../pages/machines/EditMachine';
import Companies from '../pages/companies/Companies';
import EditCompany from '../pages/companies/EditCompany';
import WorkTime from '../pages/workTime/WorkTime';
import EditWorkTime from '../pages/workTime/EditWorkTime';
import ProductionProcess from '../pages/productionProcess/ProductionProcess';
import ProductionProcessDay from '../pages/productionProcess/ProductionProcessDay';
import ProductionProcessDayStorekeeper from '../components/base/ProdProccesDayStorekeeper';
import EditWorkOrder from '../pages/workOrders/EditWorkOrder';
import WorkOrders from '../pages/workOrders/WorkOrders';
import WorkOrdersDigital from '../pages/workOrders/WorkOrdersDigital';
import ViewWorkOrder from '../pages/workOrders/ViewWorkOrder';
import Product from '../pages/product/Product';
import EditProduct from '../pages/product/EditProduct';
import ViewMaterial from '../pages/material/ViewMaterial';
import ViewMaterialRolls from '../pages/material/ViewMaterialRolls';
import Tool from '../pages/tool/Tool';
import EditTool from '../pages/tool/EditTool';
import ViewTool from '../pages/tool/ViewTool';
import RollVerification from '../pages/rollVerification';
import ProductPDFs from '../pages/product/ProductPDFs';
import EditPDF from '../pages/product/EditPDF';
import EditHilzne from '../pages/hilzne/EditHilzne';
import Hilzne from '../pages/hilzne/Hilzne';
import ViewHilzne from '../pages/hilzne/ViewHilzne';
import TypeOfFolio from '../pages/type-of-folio/TypeOfFolio';
import EditTypeOfFolio from '../pages/type-of-folio/EditTypeOfFolio';
import ViewTypeOfFolio from '../pages/type-of-folio/ViewTypeOfFolio';
import OrderToProduction from '../pages/orderToProduction/OrderToProduction';
import EditOrderToProduction from '../pages/orderToProduction/EditOrderToProduction';
import ViewOrderToProduction from '../pages/orderToProduction/ViewOrderToProduction';
import Box from '../pages/boxes/Box';
import ViewBox from '../pages/boxes/ViewBox';
import EditBox from '../pages/boxes/EditBox';
import OrderConfirmation from '../pages/orderconfirmation/OrderConfirmation';
import EditOrderConfirmation from '../pages/orderconfirmation/EditOrderConfirmation';
import ViewOrderConfirmation from '../pages/orderconfirmation/ViewOrderConfirmation';

const routes = [
  {
    label: 'NPP',
    icon: FileTextOutlined,
    allowed: ['admin', 'sales', 'superAdmin'],
    children: [
      {
        label: 'Svi npp',
        path: '/admin/orders-to-production',
        component: OrderToProduction,
        allowed: ['admin', 'sales', 'preparer', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Novi npp',
        path: '/admin/new-order-to-production',
        component: EditOrderToProduction,
        allowed: ['admin', 'sales', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Izmeni npp',
        path: '/admin/edit-order-to-production/:ordertoproductionId',
        component: EditOrderToProduction,
        allowed: ['admin', 'sales', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Pregled npp',
        path: '/admin/view-order-to-production/:ordertoproductionId',
        component: ViewOrderToProduction,
        allowed: ['admin', 'operator', 'operatorI', 'sales', 'preparer', 'superAdmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Potvrde porudžbine',
    icon: FilePptOutlined,
    allowed: ['admin', 'superAdmin', 'sales'],
    children: [
      {
        label: 'Sve potvrde porudžbine',
        path: '/admin/ordersconfirmation',
        component: OrderConfirmation,
        allowed: ['admin', 'sales', 'preparer', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Nova potvrda porudžbine',
        path: '/admin/new-orderconfirmation',
        component: EditOrderConfirmation,
        allowed: ['admin', 'superAdmin', 'sales'],
        showInMenu: false,
      },
      {
        label: 'Pregled potvrde porudžbine',
        path: '/admin/view-orderconfirmation/:id',
        component: ViewOrderConfirmation,
        allowed: ['admin', 'operator', 'operatorI', 'sales', 'preparer', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Izmena potvrde porudžbine',
        path: '/admin/edit-orderconfirmation/:id',
        component: EditOrderConfirmation,
        allowed: ['admin', 'operator', 'operatorI', 'sales', 'preparer', 'superAdmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Radni nalozi',
    icon: FileTextOutlined,
    allowed: ['admin', 'superAdmin'],
    children: [
      {
        label: 'Svi radni nalozi',
        path: '/admin/work-orders',
        component: WorkOrders,
        allowed: ['admin', 'sales', 'preparer', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Svi radni nalozi - digitala',
        path: '/admin/work-orders-digital',
        component: WorkOrdersDigital,
        allowed: ['admin', 'sales', 'preparer', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Novi radni nalog',
        path: '/admin/new-work-order',
        component: EditWorkOrder,
        allowed: ['admin', 'sales', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Izmeni radni nalog',
        path: '/admin/edit-work-order/:workOrderId',
        component: EditWorkOrder,
        allowed: ['admin', 'superAdmin', 'preparer'],
        showInMenu: false,
      },
      {
        label: 'Pregled radnog naloga',
        path: '/admin/view-work-order/:workOrderId',
        component: ViewWorkOrder,
        allowed: ['admin', 'operator', 'operatorI', 'sales', 'preparer', 'superAdmin'],
        showInMenu: false,
      },
    ],
  },

  {
    label: 'Proizvodi',
    icon: BarcodeOutlined,
    allowed: ['admin', 'preparer', 'superAdmin'],
    children: [
      {
        label: 'Svi proizvodi',
        path: '/admin/products',
        component: Product,
        allowed: ['admin', 'preparer', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Novi proizvod',
        path: '/admin/new-product',
        component: EditProduct,
        allowed: ['admin', 'preparer', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Izmeni proizvod',
        path: '/admin/edit-product/:productId',
        component: EditProduct,
        allowed: ['admin', 'preparer', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Lista PDF-ova',
        path: '/admin/product-pdf',
        component: ProductPDFs,
        allowed: ['admin', 'preparer', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Dodaj PDF',
        path: '/admin/product-pdf-add',
        component: EditPDF,
        showInMenu: false,
        allowed: ['admin', 'preparer', 'superAdmin'],
      },
    ],
  },
  {
    label: 'Materijal',
    allowed: ['admin', 'storekeeper', 'superAdmin'],
    icon: QrcodeOutlined,
    children: [
      {
        label: 'Svi materijali',
        path: '/admin/materials',
        component: Material,
        allowed: ['admin', 'storekeeper', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Novi materijal',
        path: '/admin/new-material',
        component: EditMaterial,
        allowed: ['admin', 'storekeeper', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Izmeni materijal',
        path: '/admin/edit-material/:materialId',
        component: EditMaterial,
        allowed: ['admin', 'storekeeper', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Dodaj višak rolnu',
        path: '/admin/excess-roll/:materialId',
        component: ExcessRoll,
        allowed: ['admin', 'storekeeper', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Pregled rolne',
        path: '/admin/view-roll/:rollID',
        component: ViewRoll,
        allowed: ['admin', 'storekeeper', 'superAdmin', 'operatorI', 'operator'],
        showInMenu: false,
      },
      {
        label: 'Pregled materijala',
        path: '/admin/view-material/:materialId',
        component: ViewMaterial,
        allowed: ['admin', 'storekeeper', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Pregled rolni materijala',
        path: '/admin/view-material-rolls/:materialId',
        component: ViewMaterialRolls,
        allowed: ['admin', 'storekeeper', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Izmeni rolnu',
        path: '/admin/edit-roll/:rollId',
        component: EditRoll,
        allowed: ['admin', 'storekeeper', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Verifikuj rolnu',
        path: '/roll-verification/:token',
        component: RollVerification,
        allowed: ['admin', 'storekeeper', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Sve hilzne',
        path: '/admin/hilzne',
        component: Hilzne,
        allowed: ['admin', 'storekeeper', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Nova hilzna',
        path: '/admin/new-hilzna',
        component: EditHilzne,
        allowed: ['admin', 'storekeeper', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Izmeni hilznu',
        path: '/admin/edit-hilzna/:hilznaId',
        component: EditHilzne,
        allowed: ['admin', 'storekeeper', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Pregled hilzne',
        path: '/admin/view-hilzna/:hilznaId',
        component: ViewHilzne,
        allowed: ['admin', 'storekeeper', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Svi tipovi folija',
        path: '/admin/types-of-folio',
        component: TypeOfFolio,
        allowed: ['admin', 'storekeeper', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Novi tip folije',
        path: '/admin/new-typeoffolio',
        component: EditTypeOfFolio,
        allowed: ['admin', 'storekeeper', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Izmeni tip folije',
        path: '/admin/edit-typeoffolio/:typeoffolioId',
        component: EditTypeOfFolio,
        allowed: ['admin', 'storekeeper', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Pregled tipa folije',
        path: '/admin/view-typeoffolio/:typeoffolioId',
        component: ViewTypeOfFolio,
        allowed: ['admin', 'storekeeper', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Sve kutije',
        path: '/admin/boxes',
        component: Box,
        allowed: ['admin', 'storekeeper', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Nova kutija',
        path: '/admin/new-box',
        component: EditBox,
        allowed: ['admin', 'storekeeper', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Izmeni kutiju',
        path: '/admin/edit-box/:boxId',
        component: EditBox,
        allowed: ['admin', 'storekeeper', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Pregled kutije',
        path: '/admin/view-box/:boxId',
        component: ViewBox,
        allowed: ['admin', 'storekeeper', 'superAdmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Plan proizvodnje',
    icon: TableOutlined,
    allowed: ['operator', 'operatorI', 'admin', 'sales', 'storekeeper', 'preparer', 'superAdmin'],
    children: [
      {
        label: 'Svi procesi proizvodnje',
        path: '/admin/production-process',
        component: ProductionProcess,
        allowed: ['operator', 'operatorI', 'admin', 'storekeeper', 'sales', 'preparer', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Svi procesi proizvodnje - magacioner',
        path: '/admin/production-process-day-storekeeper',
        component: ProductionProcessDayStorekeeper,
        allowed: ['storekeeper', 'superAdmin'],
        showInMenu: true,
      },
      // {
      //   label: 'New production process',
      //   path: '/admin/new-production-process',
      //   component: ProductionProcess,
      //   allowed: ['admin'],
      //   showInMenu: true,
      // },
      {
        label: 'Izmeni plan proizvodnje',
        path: '/admin/edit-production-process/:productionProcessId',
        component: ProductionProcess,
        allowed: ['operator', 'operatorI', 'admin', 'sales', 'storekeeper', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Plan proizvodnje na dan',
        path: '/admin/production-process-day',
        component: ProductionProcessDay,
        allowed: ['operator', 'operatorI', 'admin', 'sales', 'storekeeper', 'preparer', 'superAdmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Kompanije',
    icon: BankOutlined,
    allowed: ['admin', 'superAdmin'],
    children: [
      {
        label: 'Sve kompanije',
        path: '/admin/companies',
        component: Companies,
        allowed: ['admin', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Nova kompanija',
        path: '/admin/new-company',
        component: EditCompany,
        allowed: ['admin', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Izmeni kompaniju',
        path: '/admin/edit-company/:companyId',
        component: EditCompany,
        allowed: ['admin', 'superAdmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Korisnici',
    icon: UserOutlined,
    children: [
      {
        label: 'Svi korisnici',
        path: '/admin/users',
        component: Users,
        allowed: ['admin', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Novi korisnik',
        path: '/admin/new-user',
        component: EditUser,
        allowed: ['admin', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Izmeni korisnika',
        path: '/admin/edit-user/:userId',
        component: EditUser,
        allowed: ['admin', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Pregled korisnika',
        path: '/admin/view-user/:id',
        component: ViewUser,
        allowed: ['admin', 'superAdmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Mašine',
    icon: SettingOutlined,
    allowed: ['admin', 'superAdmin'],
    children: [
      {
        label: 'Sve mašine',
        path: '/admin/machines',
        component: Machines,
        allowed: ['admin', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Nova mašina',
        path: '/admin/new-machine',
        component: EditMachine,
        allowed: ['admin', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Izmeni mašinu',
        path: '/admin/edit-machine/:machineId',
        component: EditMachine,
        allowed: ['admin', 'superAdmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Radno vreme',
    icon: FieldTimeOutlined,
    allowed: ['admin', 'superAdmin'],
    children: [
      {
        label: 'Sva radna vremena',
        path: '/admin/work-time',
        component: WorkTime,
        allowed: ['admin', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Novo radno vreme',
        path: '/admin/new-work-time',
        component: EditWorkTime,
        allowed: ['admin', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Izmeni radno vreme',
        path: '/admin/edit-work-time/:workTimeId',
        component: EditWorkTime,
        allowed: ['admin', 'superAdmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Alati',
    icon: ToolOutlined,
    allowed: ['admin', 'superAdmin'],
    children: [
      {
        label: 'Svi alati',
        path: '/admin/tools',
        component: Tool,
        allowed: ['admin', 'sales', 'preparer', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Novi alat',
        path: '/admin/new-tool',
        component: EditTool,
        allowed: ['admin', 'superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Izmeni alat',
        path: '/admin/edit-tool/:toolId',
        component: EditTool,
        allowed: ['admin', 'preparer', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Pregled alata',
        path: '/admin/view-tool/:toolId',
        component: ViewTool,
        allowed: ['admin', 'sales', 'preparer', 'superAdmin'],
        showInMenu: false,
      },
    ],
  },
  // {
  //   label: 'Import Data',
  //   icon: ImportOutlined,
  //   children: [
  //     {
  //       label: 'Import Data',
  //       path: '/admin/ImportData',
  //       component: ImportData,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //   ],
  // },
  // {
  //   label: 'Languages',
  //   icon: TranslationOutlined,
  //   children: [
  //     {
  //       label: 'Languages',
  //       path: '/admin/languages',
  //       component: Languages,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //   ],
  // },
];

export default routes;
