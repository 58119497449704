import React, { useState } from 'react';
import { Button, Input, notification, Table, Space, Popconfirm, Badge, Tooltip } from 'antd';
import {
  CheckOutlined,
  SaveOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import _ from 'lodash';

const ProductionProcessDay = ({
  workOrders,
  onSubmit,
  data,
  user,
  allowVerify,
  verifyWorkOrder,
  token,
  updateHandler,
  machines,
  deleteHandler,
  reload,
  setReload,
}) => {
  const [workerTime, setWorkerTime] = useState();
  const [reRender, setReRender] = useState();

  const [reworkTime, setReworkTime] = useState();
  const [finishedProduct, setFinishedProduct] = useState();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const [materialConsumption, setMaterialConsumption] = useState();
  data.map((item, index) => {
    item.workOrder[0].deliveryDate = item.workOrder[0]?.deliveryDate.split('T')[0].split('-').reverse().join('.');
    item.workOrder[0].orderDate = item.workOrder[0].orderDate.split('T')[0].split('-').reverse().join('.');

    return item;
  });

  data.forEach((process, processIndex) => {
    let totalReservedMat = 0;
    const materials = _.get(process, 'material', []);

    if (materials.length > 0) {
      materials.forEach((material, materialIndex) => {
        const materialId = _.get(material, '_id', '');
        let reservedRolls = _.get(material, 'rollsReserved', []);
        if (materialId && reservedRolls.length > 0) {
          let filteredReservedRolls = [];

          reservedRolls.forEach((reservedRoll, reservedRollIndex) => {
            totalReservedMat += reservedRoll.materialQuantity;
            const rollMaterialId = _.get(reservedRoll, 'material', '');
            const isTheSame = rollMaterialId === materialId;
            const rollID = _.get(reservedRoll, 'roll', '');
            let rolls = _.get(reservedRoll, 'rolls', []);
            let matConsumption = _.get(reservedRoll, 'materialConsumption', []);
            let filteredMatConsuption = [];
            let filteredRolls = [];
            rolls.forEach((roll) => {
              if (rollID === roll._id) filteredRolls.push(roll);
            });
            _.set(
              data[processIndex],
              `material[${materialIndex}].rollsReserved[${reservedRollIndex}].rolls`,
              filteredRolls,
            );
            matConsumption.forEach((reservedRollConsumption) => {
              if (reservedRoll._id === reservedRollConsumption.RollReservedID)
                filteredMatConsuption.push(reservedRollConsumption);
            });
            _.set(
              data[processIndex],
              `material[${materialIndex}].rollsReserved[${reservedRollIndex}].materialConsumption`,
              filteredMatConsuption,
            );
            if (isTheSame) {
              filteredReservedRolls.push(reservedRoll);
            }
          });
          _.set(data[processIndex], `material[${materialIndex}].rollsReserved`, filteredReservedRolls);
          _.set(data[processIndex], `totalReservedMat`, totalReservedMat);
        }
      });
    }
  });

  const saveWorkerTime = async (workOrder) => {
    console.log(workOrder, workerTime, 'test', workOrder.workOrderPrintStarted);

    try {
      const res = await Axios.post(
        `${SERVER_URL}/worker-time`,
        {
          time: workerTime,
          operator: `${user.firstName} ${user.lastName}`,
          workOrder: workOrder,
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      if (res && res.data.message === 'Created') {
        notification.success({
          message: `Unos je sačuvan.`,
          placement: 'bottomRight',
        });

        setReload((prevState) => !prevState);
        setWorkerTime('');
      }
    } catch (err) {
      console.log('err', err);
      notification.error({
        message: `Došlo je do greške.`,
        placement: 'bottomRight',
      });
    }
  };

  const deleteInput = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/material-consumption/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });
      notification.success({
        message: `Unos je obrisan.`,
        placement: 'bottomRight',
      });
      setReload((prevState) => !prevState);
    } catch (err) {
      console.log('err', err);
      notification.error({
        message: `Došlo je do greške.`,
        placement: 'bottomRight',
      });
    }
  };

  const saveReworkTime = async (workOrder) => {
    try {
      console.log('workOrder', workOrder);
      const res = await Axios.post(
        `${SERVER_URL}/rework-time`,
        {
          time: reworkTime,
          operator: `${user.firstName} ${user.lastName}`,
          workOrder: workOrder,
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      if (res && res.data.message === 'Created') {
        notification.success({
          message: `Unos je sačuvan.`,
          placement: 'bottomRight',
        });
        setReload((prevState) => !prevState);
        setReworkTime('');
      }
    } catch (err) {
      console.log('err', err);
      notification.error({
        message: `Došlo je do greške.`,
        placement: 'bottomRight',
      });
    }
  };

  const saveFinishedProduct = async (workOrder) => {
    try {
      const res = await Axios.post(
        `${SERVER_URL}/finished-product`,
        {
          quantity: finishedProduct,
          operator: `${user.firstName} ${user.lastName}`,
          workOrder: workOrder,
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      if (res && res.data.message === 'Created') {
        notification.success({
          message: `Unos je sačuvan.`,
          placement: 'bottomRight',
        });

        setReload((prevState) => !prevState);
        setFinishedProduct('');
      }
    } catch (err) {
      console.log('err', err);
      notification.error({
        message: `Došlo je do greške.`,
        placement: 'bottomRight',
      });
    }
  };

  // saveMaterialConsumption(records, reservedRollRecord._id, reservedRollRecord.roll, reservedRollRecord.rolls[0].weight, materialRecords._id);
  const saveMaterialConsumption = async (records, reservedRoll, material) => {
    let spentQ = 0;
    if (records.materialConsumption.length > 0) {
      for (const matCons of records.materialConsumption) {
        spentQ =
          reservedRoll.rolls[0].weight -
          reservedRoll?.materialConsumption?.reduce((sum, { spentQuantity }) => sum + spentQuantity, 0) -
          materialConsumption;
      }
    } else {
      spentQ = reservedRoll.rolls[0].weight - materialConsumption;
    }
    try {
      const res = await Axios.post(
        `${SERVER_URL}/material-consumption`,
        {
          MaterialID: material,
          measuredQuantity: materialConsumption,
          // spentQuantity: rollID ===records.materialConsumption.(weight - records?.materialConsumption?.reduce((sum, { spentQuantity }) => sum + spentQuantity, 0)) - materialConsumption,
          spentQuantity: spentQ,
          operator: `${user.firstName} ${user.lastName}`,
          workOrder: records.workOrder[0]._id,
          RollReservedID: reservedRoll._id,
          rollID: reservedRoll.roll,
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      if (res && res.data.message === 'Created') {
        notification.success({
          message: `Unos je sačuvan.`,
          placement: 'bottomRight',
        });

        setReload((prevState) => !prevState);
        setMaterialConsumption('');
      }
    } catch (err) {
      console.log('err', err);
      notification.error({
        message: `Došlo je do greške.`,
        placement: 'bottomRight',
      });
    }
  };

  const finishPrinting = async (record) => {
    onExpand(false, record);

    try {
      if (record.workerTime.length < 1) {
        notification.error({
          message: `Unesite vreme štampanja.`,
          placement: 'bottomRight',
        });
        return;
      }
      if (record.workerTime.length < 1) {
        notification.error({
          message: `Unesite vreme štampanja.`,
          placement: 'bottomRight',
        });
        return;
      }
      if (user.role.some((r) => ['superAdmin', 'operator', 'operatorI'].includes(r))) {
        await Axios.put(
          `${SERVER_URL}/finish-workOrder/${record.workOrder[0]._id}`,
          {
            record: record,
            status: 'Odštampan',
            user: user,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        notification.success({
          message: `Štampanje je završeno`,
          placement: 'bottomRight',
        });
        setReload((prevState) => !prevState);
        return;
      }
    } catch (error) {
      console.log('err', error);
      notification.error({
        message: `Došlo je do greške.`,
        placement: 'bottomRight',
      });
    }
  };

  const closeWorkOrder = async (record) => {
    let rollsAreVerified = false;
    for (const roll of record.roll) {
      if (roll.status.includes('verifikaciju')) {
        rollsAreVerified = false;
        break;
      } else {
        rollsAreVerified = true;
      }
    }
    try {
      if (record.finishedProduct.length <= 0) {
        notification.error({
          message: `Unesite količinu gotovog proizvoda.`,
          placement: 'bottomRight',
        });
        return;
      }
      if (record.reworkTime.length <= 0) {
        notification.error({
          message: `Unesite vreme dorade.`,
          placement: 'bottomRight',
        });
        return;
      }
      if (!rollsAreVerified) {
        notification.error({
          message: `Magacioner još uvek nije verifikovao rolne.`,
          placement: 'bottomRight',
        });
        return;
      }

      onExpand(false, record);

      if (record.workOrder[0].workOrderStatus === 'Odštampan') {
        await Axios.put(
          `${SERVER_URL}/close-workOrder/${record.workOrder[0]._id}`,
          {
            record: record,
            status: 'zatvoren',
            user: user,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        notification.success({
          message: `Nalog je zatvoren`,
          placement: 'bottomRight',
        });
        setReload((prevState) => !prevState);
      }
    } catch (error) {
      console.log('err', error);
      notification.error({
        message: `Došlo je do greške.`,
        placement: 'bottomRight',
      });
    }
  };

  const deleteFromProduction = async (id, record) => {
    for (let i = 0; i < record.roll.length; i++) {
      await Axios.put(
        `${SERVER_URL}/roll-status/${record.roll[i]._id}`,
        { status: 'Čeka verifikaciju (vraćanje iz proizvodnje)' },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    }
    deleteHandler(id);

    setReload((prevState) => !prevState);
  };

  const expandedRowRender = (records, index) => {
    let verifiedRolls = 0;
    for (const roll of records.roll) {
      if (roll?.status?.includes('verifikaciju')) {
        verifiedRolls++;
      }
    }

    const columnsInputWorkTime = [
      {
        title: 'Vreme štampanja',
        width: '30%',
        render: () => (
          <div>
            <Input
              type='number'
              disabled={
                !user.role.some((r) => ['superAdmin', 'operator', 'operatorI'].includes(r)) ||
                records.workOrder[0].workOrderStatus === 'zatvoren' ||
                records.workOrder[0].workOrderStatus === 'Odštampan' ||
                records.workOrder[0].workOrderAuthenticationStatus === 'Poslata na overu' ||
                records.workOrder[0].workOrderAuthenticationStatus === 'Potrebna overa' ||
                !data[0] ||
                data[index - 1]?.workOrder[0]?.workOrderStatus === 'priprema' ||
                data[index - 1]?.workOrder[0]?.workOrderStatus === 'u proizvodnji'
              }
              value={workerTime}
              onChange={(e) => setWorkerTime(e.target.value)}
            />
          </div>
        ),
      },
      {
        title: 'Operater',
        width: '60%',
        render: () => <Input disabled={true} value={`${user.firstName} ${user.lastName}`} />,
      },

      {
        title: 'Akcije',
        render: () => (
          <Button
            disabled={
              !user.role.some((r) => ['superAdmin', 'operator', 'operatorI', 'admin'].includes(r)) ||
              records.workOrder[0].workOrderStatus === 'zatvoren' ||
              records.workOrder[0].workOrderStatus === 'Odštampan' ||
              !data[0] ||
              data[index - 1]?.workOrder[0]?.workOrderStatus === 'priprema'
            }
            style={{ height: '25px', fontSize: '10px' }}
            onClick={() => {
              if (workerTime < 0) {
                notification.error({
                  message: `Unos mora biti veći od 0.`,
                  placement: 'bottomRight',
                });
                return;
              }
              if (workerTime === '' || workerTime === null || workerTime === undefined) {
                notification.error({
                  message: `Unesite vreme štampanja.`,
                  placement: 'bottomRight',
                });
                return;
              }
              if (verifiedRolls > 0) {
                notification.error({
                  message: `Rolne nisu verifikovane.`,
                  placement: 'bottomRight',
                });
                return;
              }
              if (workerTime > 0) {
                saveWorkerTime(records.workOrder[0]._id);
                return;
              }
            }}
          >
            Sačuvaj
          </Button>
        ),
      },
    ];
    const columnsInputFinishedProduct = [
      {
        title: 'Ukupna količina gotovog proizvoda',
        width: '30%',
        render: () => (
          <Input
            type='number'
            disabled={
              records.workOrder[0].workOrderAuthenticationStatus === 'Poslata na overu' ||
              records.workOrder[0].workOrderAuthenticationStatus === 'Potrebna overa' ||
              !user.role.some((r) => ['superAdmin', 'operatorI', 'operator'].includes(r)) ||
              records.workOrder[0].workOrderStatus !== 'Odštampan'
            }
            value={finishedProduct}
            onChange={(e) => setFinishedProduct(e.target.value)}
          />
        ),
      },
      {
        title: 'Operater',
        width: '60%',
        render: () => <Input disabled={true} value={`${user.firstName} ${user.lastName}`} />,
      },
      {
        title: 'Akcije',
        render: () => (
          <Button
            disabled={
              !user.role.some((r) => ['superAdmin', 'operatorI'].includes(r)) ||
              records.workOrder[0].workOrderStatus !== 'Odštampan'
            }
            style={{ height: '25px', fontSize: '10px' }}
            onClick={() => {
              if (
                (finishedProduct > records.workOrder[0].printingQuantity * 1.05 ||
                  finishedProduct < records.workOrder[0].printingQuantity * 0.95) &&
                !user.role.some((r) => ['superAdmin'].includes(r))
              ) {
                notification.error({
                  message: 'Napravljena količina može odstupati +-5% od predviđene, kontaktirajte nadređenog.',
                  placement: 'bottomRight',
                });
                return;
              }

              if (user.role.some((r) => ['superAdmin'].includes(r)) && finishedProduct < 0) {
                notification.error({
                  message: `Unos mora biti veći od 0.`,
                  placement: 'bottomRight',
                });
                return;
              }
              if (
                !user.role.some((r) => ['superAdmin'].includes(r)) &&
                finishedProduct <= +records.finishedProduct[records.finishedProduct?.length - 1]?.quantity
              ) {
                notification.error({
                  message: `Unos ne može biti manji ili jednak sa poslednjim unosom.`,
                  placement: 'bottomRight',
                });
                return;
              }
              if (finishedProduct === '' || finishedProduct === null || finishedProduct === undefined) {
                notification.error({
                  message: `Unesite ukupnu količinu napravljenog gotovog proizvoda.`,
                  placement: 'bottomRight',
                });
                return;
              }
              if (
                (user.role.some((r) => ['superAdmin'].includes(r)) && finishedProduct > 0) ||
                finishedProduct <= records.workOrder[0].printingQuantity * 1.05
              ) {
                saveFinishedProduct(records.workOrder[0]._id);
              }
            }}
          >
            Sacuvaj
          </Button>
        ),
      },
    ];

    const deleteFourInputFields = async (id, recordType) => {
      await Axios.delete(`${SERVER_URL}/${recordType}/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });
      setReload((prevState) => !prevState);
    };

    const columnsInputReworkTime = [
      {
        title: 'Vreme dorade',
        width: '30%',
        render: () => (
          <Input
            type='number'
            disabled={
              records.workOrder[0].workOrderAuthenticationStatus === 'Poslata na overu' ||
              records.workOrder[0].workOrderAuthenticationStatus === 'Potrebna overa' ||
              !user.role.some((r) => ['superAdmin', 'operatorI', 'operator'].includes(r)) ||
              records.workOrder[0].workOrderStatus !== 'Odštampan'
            }
            value={reworkTime}
            onChange={(e) => setReworkTime(e.target.value)}
          />
        ),
      },
      {
        title: 'Operater',
        width: '60%',
        render: () => <Input disabled={true} value={`${user.firstName} ${user.lastName}`} />,
      },
      {
        title: 'Akcije',
        render: () => (
          <Button
            disabled={
              !user.role.some((r) => ['superAdmin', 'operatorI', 'operator'].includes(r)) ||
              records.workOrder[0].workOrderStatus !== 'Odštampan'
            }
            style={{ height: '25px', fontSize: '10px' }}
            onClick={() => {
              if (reworkTime < 0) {
                notification.error({
                  message: `Unos mora biti veći od 0.`,
                  placement: 'bottomRight',
                });
                return;
              }
              if (reworkTime === '' || reworkTime === null || reworkTime === undefined) {
                notification.error({
                  message: `Unesite vreme dorade.`,
                  placement: 'bottomRight',
                });
                return;
              }
              if (reworkTime > 0) {
                saveReworkTime(records.workOrder[0]._id);
                return;
              }
            }}
          >
            Sacuvaj
          </Button>
        ),
      },
    ];

    const columnsWorkerTime = [
      { title: 'Vreme štampanja', dataIndex: 'time', key: 'time', width: '30%' },
      { title: 'Operater', dataIndex: 'operator', key: 'operator', width: '70%' },
      {
        title: 'Akcije',
        key: 'actions',
        render: (record) => (
          <div>
            {user.role.some((r) => ['superAdmin'].includes(r)) &&
              records.workOrder[0].workOrderStatus !== 'Odštampan' &&
              records.workOrder[0].workOrderStatus !== 'zatvoren' && (
                <Popconfirm
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  placement='left'
                  title={`Potvrdi brisanje vremena štampanja`}
                  okText='Da'
                  cancelText='Ne'
                  onConfirm={() => {
                    deleteFourInputFields(record._id, 'worker-time');
                  }}
                >
                  <DeleteOutlined title={`Obriši unos`} />
                </Popconfirm>
              )}
          </div>
        ),
      },
    ];
    const columnsReworkTime = [
      { title: 'Vreme dorade', dataIndex: 'time', key: 'time', width: '30%' },
      { title: 'Operater', dataIndex: 'operator', key: 'operator', width: '70%' },
      {
        title: 'Akcije',
        key: 'actions',
        render: (record) => (
          <div>
            {user.role.some((r) => ['superAdmin'].includes(r)) &&
              records.workOrder[0].workOrderStatus !== 'zatvoren' && (
                <Popconfirm
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  placement='left'
                  title={`Potvrdi brisanje vremena dorade`}
                  okText='Da'
                  cancelText='Ne'
                  onConfirm={() => {
                    deleteFourInputFields(record._id, 'rework-time');
                  }}
                >
                  <DeleteOutlined title={`Obriši unos`} />
                </Popconfirm>
              )}
          </div>
        ),
      },
    ];
    const columnsFinishedProduct = [
      { title: 'Ukupna količina potrošenog materijala', dataIndex: 'quantity', key: 'finishedProduct', width: '30%' },
      { title: 'Operater', dataIndex: 'operator', key: 'operator', width: '70%' },
      {
        title: 'Akcije',
        key: 'actions',
        render: (record) => (
          <div>
            {user.role.some((r) => ['superAdmin'].includes(r)) &&
              records.workOrder[0].workOrderStatus !== 'zatvoren' && (
                <Popconfirm
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  placement='left'
                  title={`Potvrdi brisanje gotovog proizvoda`}
                  okText='Da'
                  cancelText='Ne'
                  onConfirm={() => {
                    deleteFourInputFields(record._id, 'finished-product');
                  }}
                >
                  <DeleteOutlined title={`Obriši unos`} />
                </Popconfirm>
              )}
          </div>
        ),
      },
    ];
    const columnsMaterials = [
      { title: 'Materijal', dataIndex: 'name', key: 'finishedProduct' },
      { title: 'Šifra materijala', dataIndex: 'code', key: 'operator', width: '70%' },
    ];

    return (
      <div>
        {/* VREME RADA - START */}

        <Table
          rowKey={(record) => record._id}
          size='medium'
          bordered
          columns={columnsInputWorkTime}
          dataSource={[{}]}
          pagination={false}
        />

        <Table
          rowKey={(record) => record._id}
          bordered
          size='medium'
          showHeader={false}
          columns={columnsWorkerTime}
          dataSource={records.workerTime}
          pagination={false}
        />
        {/* VREME RADA - END */}
        {/*  */}
        <Table
          rowKey={(record) => record._id}
          bordered
          size='medium'
          columns={columnsMaterials}
          dataSource={records.material}
          pagination={false}
          expandable={{
            expandedRowRender: (materialRecords) => {
              const columnsMaterialRolls = [
                {
                  key: 'id',
                  title: 'ID rolne',
                  dataIndex: ['rolls', '0', 'rollID'],
                },
                {
                  title: 'LAGER(TM/KG)',
                  dataIndex: 'asd',
                  render: (text, record, index) => (
                    <p>
                      {record.rolls[0].length}({record.rolls[0].weight.toFixed(2)})
                    </p>
                  ),
                },
                {
                  title: 'Rez. količina(TM/KG)',
                  dataIndex: 'reserved quantity',
                  render: (text, record, index) => (
                    <p>
                      {record.materialQuantity}(
                      {materialRecords.calculationMethod === 't*1000/p/s*1000'
                        ? (
                            ((record.materialQuantity / 1000) *
                              materialRecords.specificWeight *
                              materialRecords.width) /
                            1000
                          ).toFixed(2)
                        : (
                            ((record.materialQuantity * materialRecords.width) / 1000) *
                            (record.rolls[0].initialWeight / record.rolls[0].initialState)
                          ).toFixed(2)}
                      )
                    </p>
                  ),
                },

                {
                  title: 'Potrošena količina(TM/KG)',
                  dataIndex: 'spent quantity',
                  render: (text, record, index) =>
                    materialRecords.calculationMethod === 't*1000/p/s*1000' && record.materialConsumption.length > 0 ? (
                      <p>
                        {(
                          ((
                            record?.materialConsumption?.reduce((sum, { spentQuantity }) => sum + spentQuantity, 0) /
                            materialRecords.specificWeight
                          ).toFixed(4) /
                            materialRecords.width) *
                          1000000
                        ).toFixed(0)}
                        (
                        {record?.materialConsumption
                          ?.reduce((sum, { spentQuantity }) => sum + spentQuantity, 0)
                          .toFixed(2)}
                        )
                      </p>
                    ) : materialRecords.calculationMethod === 'd*s/1000' && record.materialConsumption.length > 0 ? (
                      <p>
                        {(
                          (((record?.rolls[0]?.initialState / record?.rolls[0]?.initialWeight) *
                            record?.materialConsumption?.reduce((sum, { spentQuantity }) => sum + spentQuantity, 0)) /
                            materialRecords?.width) *
                          1000
                        ).toFixed(0)}
                        (
                        {record?.materialConsumption
                          ?.reduce((sum, { spentQuantity }) => sum + spentQuantity, 0)
                          .toFixed(2)}
                        )
                      </p>
                    ) : (
                      <p>0(0)</p>
                    ),
                },
              ];

              return (
                <div>
                  <Table
                    rowKey={(record) => record._id}
                    expandable={{
                      expandedRowRender: (reservedRollRecord) => {
                        const columnsReservedRolls = [
                          {
                            key: 'quantity',
                            dataIndex: 'measuredQuantity',
                            width: '30%',
                          },
                          {
                            key: 'operator',
                            dataIndex: 'operator',
                            width: '70%',
                          },
                          {
                            title: 'Akcije',
                            render: (record) => (
                              <div>
                                {user.role.some((r) => ['admin', 'superAdmin'].includes(r)) &&
                                  records.workOrder[0].workOrderStatus !== 'Odštampan' &&
                                  records.workOrder[0].workOrderStatus !== 'zatvoren' && (
                                    <Popconfirm
                                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                      placement='left'
                                      title={`Potvrdi brisanje unosa`}
                                      okText='Da'
                                      cancelText='Ne'
                                      onConfirm={() => {
                                        deleteInput(record._id);
                                      }}
                                    >
                                      <DeleteOutlined title={`Obriši unos`} />
                                    </Popconfirm>
                                  )}
                              </div>
                            ),
                          },
                        ];

                        const columnsInputMaterial = [
                          {
                            title: 'Izmerena količina(kg)',
                            width: '30%',
                            render: () => (
                              <Input
                                type='number'
                                disabled={
                                  !user.role.some((r) => ['superAdmin', 'operator', 'operatorI'].includes(r)) ||
                                  records.workOrder[0].workOrderStatus === 'zatvoren' ||
                                  records.workOrder[0].workOrderStatus === 'Odštampan' ||
                                  records.workOrder[0].workOrderAuthenticationStatus === 'Poslata na overu' ||
                                  records.workOrder[0].workOrderAuthenticationStatus === 'Potrebna overa' ||
                                  !data[0] ||
                                  data[index - 1]?.workOrder[0]?.workOrderStatus === 'priprema' ||
                                  data[index - 1]?.workOrder[0]?.workOrderStatus === 'u proizvodnji'
                                }
                                // value={materialConsumption}
                                onChange={(e) => setMaterialConsumption(e.target.value)}
                              />
                            ),
                          },
                          {
                            title: 'Operater',
                            width: '60%',
                            render: () => <Input disabled={true} value={`${user.firstName} ${user.lastName}`} />,
                          },
                          {
                            title: 'Akcije',
                            render: () => (
                              <Button
                                disabled={
                                  !user.role.some((r) => ['superAdmin', 'operator', 'operatorI'].includes(r)) ||
                                  records.workOrder[0].workOrderStatus === 'zatvoren' ||
                                  records.workOrder[0].workOrderStatus === 'Odštampan' ||
                                  !data[0] ||
                                  data[index - 1]?.workOrder[0]?.workOrderStatus === 'priprema'
                                }
                                style={{ height: '25px', fontSize: '10px' }}
                                onClick={() => {
                                  if (
                                    materialConsumption === '' ||
                                    materialConsumption === null ||
                                    materialConsumption === undefined
                                  ) {
                                    notification.error({
                                      message: `Unesite izmerenu količinu.`,
                                      placement: 'bottomRight',
                                    });
                                    return;
                                  }
                                  if (+materialConsumption > +reservedRollRecord.rolls[0].weight) {
                                    notification.error({
                                      message: `Izmerena količina ne može biti veća od prvobitne težine.`,
                                      placement: 'bottomRight',
                                    });
                                    return;
                                  }
                                  if (+materialConsumption < 0) {
                                    notification.error({
                                      message: `Izmerena količina ne može biti manja od 0.`,
                                      placement: 'bottomRight',
                                    });
                                    return;
                                  }
                                  if (
                                    +materialConsumption ===
                                    +reservedRollRecord?.materialConsumption[
                                      reservedRollRecord?.materialConsumption?.length - 1
                                    ]?.measuredQuantity
                                  ) {
                                    notification.error({
                                      message: `Izmerena količina ne može biti ista kao poslednji unos.`,
                                      placement: 'bottomRight',
                                    });
                                    return;
                                  }
                                  if (
                                    +materialConsumption >
                                    +reservedRollRecord?.materialConsumption[
                                      reservedRollRecord?.materialConsumption?.length - 1
                                    ]?.measuredQuantity
                                  ) {
                                    notification.error({
                                      message: `Izmerena količina ne može biti veća od poslednjeg unosa.`,
                                      placement: 'bottomRight',
                                    });
                                    return;
                                  }
                                  if (verifiedRolls > 0) {
                                    notification.error({
                                      message: `Rolne nisu verifikovane.`,
                                      placement: 'bottomRight',
                                    });
                                    return;
                                  }
                                  if (!user.role.includes('superAdmin')) {
                                    if (
                                      user.role.some((r) => !['superAdmin'].includes(r)) &&
                                      parseFloat(materialConsumption).toFixed(2) <
                                        parseFloat(
                                          parseFloat(reservedRollRecord.rolls[0].weight).toFixed(3) -
                                            (materialRecords.calculationMethod === 't*1000/p/s*1000'
                                              ? (
                                                  ((reservedRollRecord.materialQuantity / 1000) *
                                                    materialRecords.specificWeight *
                                                    materialRecords.width) /
                                                  1000
                                                ).toFixed(2)
                                              : (
                                                  ((reservedRollRecord.materialQuantity * materialRecords.width) /
                                                    1000) *
                                                  (reservedRollRecord.rolls[0].weight /
                                                    reservedRollRecord.rolls[0].state)
                                                ).toFixed(2)),
                                        )
                                    ) {
                                      notification.error({
                                        message: `Utrošeno je više materijala nego predviđeno, kontaktirajte nadređenog.`,
                                        placement: 'bottomRight',
                                      });
                                      return;
                                    }
                                  } else {
                                    notification.info({
                                      message: `SuperAdmin je izvršio unos.`,
                                      placement: 'bottomRight',
                                    });
                                  }

                                  // console.log(parseFloat(materialConsumption).toFixed(2));
                                  // console.log(
                                  //   parseFloat(
                                  //     parseFloat(reservedRollRecord.rolls[0].weight).toFixed(3) -
                                  //       (materialRecords.calculationMethod === 't*1000/p/s*1000'
                                  //         ? (
                                  //             ((reservedRollRecord.materialQuantity / 1000) *
                                  //               materialRecords.specificWeight *
                                  //               materialRecords.width) /
                                  //             1000
                                  //           ).toFixed(2)
                                  //         : (
                                  //             ((reservedRollRecord.materialQuantity * materialRecords.width) / 1000) *
                                  //             (reservedRollRecord.rolls[0].weight / reservedRollRecord.rolls[0].state)
                                  //           ).toFixed(2)),
                                  //   ),
                                  // );
                                  // console.log();
                                  // console.log('ok');
                                  saveMaterialConsumption(records, reservedRollRecord, materialRecords._id);
                                  setMaterialConsumption('');
                                }}
                              >
                                Sačuvaj
                              </Button>
                            ),
                          },
                        ];
                        //TABLES FOR MATERIAL CONSUMPTION
                        return (
                          <div>
                            <Table
                              rowKey={(record) => record._id}
                              bordered
                              size='medium'
                              columns={columnsInputMaterial}
                              dataSource={[{}]}
                              pagination={false}
                            />
                            <Table
                              rowKey={(record) => record._id}
                              bordered
                              showHeader={false}
                              size='medium'
                              columns={columnsReservedRolls}
                              dataSource={reservedRollRecord.materialConsumption}
                              pagination={false}
                            />
                          </div>
                        );
                      },
                    }}
                    bordered
                    size='medium'
                    columns={columnsMaterialRolls}
                    dataSource={materialRecords.rollsReserved}
                    pagination={false}
                  />
                </div>
              );
            },
          }}
        />
        {user.role.some((r) => ['admin', 'superAdmin', 'operatorI'].includes(r)) && (
          <div>
            <Table
              style={{ marginLeft: '0px' }}
              rowKey={(record) => record._id}
              bordered
              size='medium'
              columns={columnsInputReworkTime}
              dataSource={[{}]}
              pagination={false}
            />
            <Table
              style={{ marginLeft: '0px' }}
              rowKey={(record) => record._id}
              bordered
              size='medium'
              columns={columnsReworkTime}
              dataSource={records.reworkTime}
              showHeader={false}
              pagination={false}
            />
            <Table
              style={{ marginLeft: '0px' }}
              rowKey={(record) => record._id}
              bordered
              size='medium'
              columns={columnsInputFinishedProduct}
              dataSource={[{}]}
              pagination={false}
            />
            <Table
              style={{ marginLeft: '0px' }}
              rowKey={(record) => record._id}
              bordered
              size='medium'
              columns={columnsFinishedProduct}
              dataSource={records.finishedProduct}
              showHeader={false}
              pagination={false}
            />
          </div>
        )}
      </div>
    );
  };

  const onExpand = (expanded, record) => {
    if (expandedRowKeys.includes(record._id)) {
      setExpandedRowKeys(expandedRowKeys.filter((item) => item !== record._id));
    } else {
      setExpandedRowKeys((current) => [...current, record._id]);
    }
  };

  // COLUMNS FOR MAIN TABLE
  const columnsWorkOrders = [
    { title: 'P.V.P.', render: (record) => `${record.workOrder[0].productionTime}h` },
    { title: 'Radni nalog', dataIndex: ['workOrder', '0', 'code'], key: 'workOrder' },
    { title: 'Kupac', dataIndex: ['workOrder', '0', 'companyName'], key: 'customer' },
    { title: 'Proizvod', dataIndex: ['workOrder', '0', 'productName'], key: 'product' },
    {
      title: 'Proizvod 2',
      render: (record) => (record.workOrder[0].productNameSecond ? `${record.workOrder[0].productNameSecond}` : '/'),
    },
    { title: 'Obim cilindra/Z', dataIndex: ['workOrder', '0', 'cilinderVolume'], key: 'cilinderVolume' },
    { title: 'Broj boja', dataIndex: ['workOrder', '0', 'numberOfColors'], key: 'numberOfColors' },
    { title: 'Tiraž za štampu', dataIndex: ['workOrder', '0', 'printingQuantity'], key: 'printingQuantity' },
    {
      title: 'Ukupni dobrih metara',
      render: (text, record) => {
        let totalGoodMeters = 0;

        for (const material of record.material) {
          for (const iterator of material.rollsReserved) {
            totalGoodMeters += iterator.goodMeters;
          }
        }

        return totalGoodMeters;
      },
    },
    {
      title: 'Ukupna količina materijala',
      render: (record) => {
        // console.log(record.material[0].type);
        // if (record.material[0].type === 'm2') {
        return `${record.totalReservedMat} m`;
        // } else if (record.material[0].type === 'kg') {
        //   return `${record.totalReservedMat} kg`;
        // }
      },
    },
    {
      title: 'PKM',
      ////potrosena kolicina materijala
      key: 'pkm',
      render: (record) => {
        let pkm = 0;

        if (record.material[0].type === 'kg') {
          if (record.materialConsumption && record.materialConsumption.length > 0) {
            for (const iterator of record.materialConsumption) {
              pkm += iterator.spentQuantity;
            }
          }

          if (pkm > 0) {
            if (record.material[0].type === 'm2') {
              return `${pkm.toFixed(2)} m2`;
            } else if (record.material[0].type === 'kg') {
              return `${pkm.toFixed(2)} kg`;
            }
          } else {
            return `0 kg`;
          }
        } else if (record.material[0].type === 'm2') {
          let squareMeters = (record.material[0].width / 1000) * record.roll[0].initialLength;
          let spentKG = 0;
          let oneKGisSquareMeters = squareMeters / record.roll[0].initialWeight;

          if (record.materialConsumption && record.materialConsumption.length > 0) {
            for (const iterator of record.materialConsumption) {
              spentKG += iterator.spentQuantity;
            }
          }

          pkm = `${oneKGisSquareMeters * spentKG} m2`;
        }

        return pkm;
      },
    },
    {
      title: 'RUPM',
      ////razlika u potrosenom mateirjalu
      key: 'rupm',
      render: (record) => {
        let totalRes = 0;
        let pkm = 0;

        if (record.material[0].type === 'kg') {
          if (record.materialConsumption && record.materialConsumption.length > 0) {
            for (const iterator of record.materialConsumption) {
              pkm += iterator.spentQuantity;
            }
          }

          if (
            record.workOrder[0].workOrderStatus &&
            (record.workOrder[0].workOrderStatus === 'Odštampan' || record.workOrder[0].workOrderStatus === 'zatvoren')
          ) {
            if (record.material[0].type === 'm2') {
              for (let i = 0; i < record.material.length; i++) {
                for (let j = 0; j < record.material[i].rollsReserved.length; j++) {
                  totalRes += record.material[i].rollsReserved[j].materialQuantity;
                }
              }
              return `${(totalRes - pkm).toFixed(2)} m2`;
            } else if (record.material[0].type === 'kg') {
              for (let i = 0; i < record.material.length; i++) {
                for (let j = 0; j < record.material[i].rollsReserved.length; j++) {
                 let priv = parseFloat(
                    ((record.material[i].rollsReserved[j].materialQuantity / 1000) *
                      record.material[i].specificWeight *
                      record.material[i].width) /
                    1000
                  ).toFixed(2);

                  totalRes = parseFloat(priv) + parseFloat(totalRes)
                }
              }

              return `${(totalRes - pkm).toFixed(2)} kg`;
            }
          } else {
            if (record.material[0].type === 'm2') {
              return `0 m2`;
            } else if (record.material[0].type === 'kg') {
              return `0 kg`;
            }
          }
        } else if (record.material[0].type === 'm2') {
          // console.log(record);
          if (
            record.workOrder[0].workOrderStatus &&
            (record.workOrder[0].workOrderStatus === 'Odštampan' || record.workOrder[0].workOrderStatus === 'zatvoren')
          ) {
            let shouldSpent = (record.material[0].width / 1000) * record.totalReservedMat;
            let squareMeters = (record.material[0].width / 1000) * record.roll[0].initialLength;
            let spentKG = 0;
            let oneKGisSquareMeters = squareMeters / record.roll[0].initialWeight;

            if (record.materialConsumption && record.materialConsumption.length > 0) {
              for (const iterator of record.materialConsumption) {
                spentKG += iterator.spentQuantity;
              }
            }

            pkm = oneKGisSquareMeters * spentKG;

            return `${shouldSpent - pkm} m2`;
          } else {
            return `0 m2`;
          }
        }
        ////kolicina materijala(planirana) - potrosena kolicina materijala
      },
    },
    { title: 'RVP', render: (record) => `${record.workerTime.reduce((sum, { time }) => sum + time, 0)}h` },
    { title: 'RVD', render: (record) => `${record.reworkTime.reduce((sum, { time }) => sum + time, 0)}h` },
    {
      title: 'Status',
      width: '6%',
      render: (record) => <Badge status='success' text={record.workOrder[0].workOrderStatus} />,
    },
    {
      title: 'Status overe',
      width: '6%',
      render: (record) => <Badge status='success' text={record.workOrder[0].workOrderAuthenticationStatus} />,
    },

    {
      title: 'Realizovan tiraž',
      render: (record) => record?.finishedProduct[record?.finishedProduct?.length - 1]?.quantity,
    },
    { title: 'Datum PO', dataIndex: ['workOrder', '0', 'orderDate'], key: 'orderDate' },
    { title: 'Datum isporuke', dataIndex: ['workOrder', '0', 'deliveryDate'], key: 'deliveryDate' },
    {
      title: 'Akcije',
      key: 'actions',
      render: (record) => (
        <div>
          <Space>
            <Link to={`/admin/view-work-order/${record.workOrder[0]._id}`}>
              <EyeOutlined title={`Pogledaj radni nalog`} />
            </Link>
            {/* {user.role.some((r) => ['admin', 'superAdmin'].includes(r)) && record.workOrder[0].workOrderStatus !== 'Odštampan' && record.workOrder[0].workOrderStatus !== 'zatvoren' &&
              <Link
                to={`/admin/edit-work-order/${record.workOrder[0]._id}`}>
                <EditOutlined
                  title={`Izmeni radni nalog`} />
              </Link>
            } */}
            {user.role.some((r) => ['admin', 'superAdmin', 'operator', 'operatorI'].includes(r)) &&
              record.workOrder[0].workOrderStatus !== 'Odštampan' &&
              record.workOrder[0].workOrderStatus !== 'zatvoren' &&
              record?.materialConsumption?.length > 0 && (
                <Popconfirm
                  placement='left'
                  title={`Potvrdi završetak štampanja`}
                  okText='Da'
                  cancelText='Ne'
                  onConfirm={() => {
                    finishPrinting(record);
                  }}
                >
                  <CheckOutlined title={`Završeno štampanje`} />
                </Popconfirm>
              )}
            {user.role.some((r) => ['admin', 'superAdmin', 'operatorI', 'operator'].includes(r)) &&
              record.workOrder[0].workOrderStatus === 'Odštampan' && (
                <Popconfirm
                  placement='left'
                  title={`Potvrdi zatvaranje radnog naloga`}
                  okText='Da'
                  cancelText='Ne'
                  onConfirm={() => {
                    closeWorkOrder(record);
                  }}
                >
                  <SaveOutlined title={`Zatvori radni nalog`} />
                </Popconfirm>
              )}
            {user.role.some((r) => ['admin', 'superAdmin'].includes(r)) &&
              record.workOrder[0].workOrderStatus !== 'Odštampan' &&
              record.workOrder[0].workOrderStatus !== 'zatvoren' && (
                <Popconfirm
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  placement='left'
                  title={`Potvrdi brisanje radnog naloga iz plana proizvodnje`}
                  okText='Da'
                  cancelText='Ne'
                  onConfirm={() => {
                    deleteFromProduction(record._id, record);
                  }}
                >
                  {!record?.split && <DeleteOutlined title={`Obriši iz plana proizvodnje`} />}
                </Popconfirm>
              )}
          </Space>
        </div>
      ),
    },
  ];

  return (
    <div style={{ marginRight: '50px' }} className='panel panel-body'>
      <div className='panel-body'>
        <Table
          rowKey={(record) => record._id}
          size='medium'
          bordered
          columns={columnsWorkOrders}
          expandable={{ expandedRowRender, expandedRowKeys, onExpand }}
          dataSource={data}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default ProductionProcessDay;
