import React, { useState, useEffect, useContext } from 'react';
import { Tabs, Card, Input, Form, Switch, Checkbox, Button, Table, InputNumber, Modal, notification } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import moment from 'moment';
import { UserContext } from '../../App';
import { PlusSquareOutlined } from '@ant-design/icons';
import { set } from 'lodash';
import calculateTotal from '../../util/calculateTotal';

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 9 },
};
const { TextArea } = Input;

const OrderConfirmationViewForm = ({ data, token }) => {
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const [ordersList, setOrdersList] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [itemPrice, setItemPrice] = useState([]);
  const [itemQuantity, setItemQuantity] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [reRender, setReRender] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [emailValue, setEmailValue] = useState([]);
  const [mailsArr, setMailsArr] = useState([]);
  const [extraCharges, setExtraCharges] = useState([]);
  const [isPriceInEuroActive, setisPriceInEuroActive] = useState(false);
  const [newColumns, setNewColumns] = useState([]);
  const [eurCourse, setEurCourse] = useState(118);

  let initialValues = { ...data };

  initialValues.date = moment(initialValues.date).format('DD. MM. YYYY.');
  initialValues.expectedDeliveryDate = moment(initialValues.expectedDeliveryDate).format('DD. MM. YYYY.');

  useEffect(() => {
    if (data && data.orders && data.orders.length > 0) {
      ///spisak naloga u potvrdi
      data.orders.forEach((element) => {
        setOrdersList((current) => [...current, `${element.code}`]);
      });
    }

    fetchOrderConfirmationItems();
  }, []);

  useEffect(() => {
    //racuna total
    const total = calculateTotal(itemPrice, orderItems, extraCharges);
    setTotalPrice(total);
  }, [itemPrice, orderItems, extraCharges]);

  useEffect(() => {
    extraFieldsHandler();
  }, [isPriceInEuroActive, itemPrice, eurCourse]);
  const extraFieldsHandler = () => {
    if (isPriceInEuroActive) {
      const newColumn = [...columns];
      newColumn.splice(5, 0, {
        title: 'Ukupno u EUR bez PDV-a',
        render: (record) => {
          return `${((record.quantity * itemPrice[record.index]) / eurCourse).toFixed(2)} €`;
        },
      });
      setNewColumns(newColumn);
      return;
    }
    setNewColumns([]);
  };

  const fetchOrderConfirmationItems = async () => {
    if (initialValues && initialValues.email) {
      setEmailValue([initialValues.email]);
      setMailsArr([{}]);
    }

    const items = await Axios.get(`${SERVER_URL}/orderconfirmation-items/${data._id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    for (let i = 0; i < items.data.length; i++) {
      items.data[i].index = i;
    }

    //Tu dobijam vrednosti za printForms i toolsIsCharging
    ///////////////////////////////////////////////////////////////
    let extraChargesArray = [];
    for (let i = 0; i < items.data.length; i++) {
      let orderConfirmationId = items.data[i].orderconfirmation;

      let orderConfirmation = await Axios.get(`${SERVER_URL}/orderconfirmation/${orderConfirmationId}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      let {
        data: { orders: orderFromConfirmation },
      } = orderConfirmation;

      let printForms =
        orderFromConfirmation[i] && orderFromConfirmation[i].printForms ? orderFromConfirmation[i].printForms : null;
      let toolsIsCharging =
        orderFromConfirmation[i] && orderFromConfirmation[i].toolsIsCharging
          ? orderFromConfirmation[i].toolsIsCharging
          : null;

      if (
        printForms?.toUpperCase() === 'NE' ||
        printForms === '0' ||
        printForms === 0 ||
        !printForms ||
        printForms === ''
      ) {
        extraChargesArray.push({ printForms: false });
      } else {
        extraChargesArray.push({ printForms: true });
      }
      if (
        toolsIsCharging?.toUpperCase() === 'NE' ||
        toolsIsCharging?.toUpperCase() === '0' ||
        toolsIsCharging?.toUpperCase() === 0 ||
        !toolsIsCharging ||
        toolsIsCharging === ''
      ) {
        extraChargesArray.push({ toolsIsCharging: false });
      } else {
        extraChargesArray.push({ toolsIsCharging: true });
      }
    }

    const printFormsConditionExists = extraChargesArray.some((item) => item.printForms);

    const toolsIsChargingConditionExists = extraChargesArray.some((item) => item.toolsIsCharging);
    let totalNumberOfColors = +data.orders[0].numberOfColors;

    if (data && data.orders[0].additionalWorkOrderData.length > 0) {
      totalNumberOfColors = data.orders[0]?.additionalWorkOrderData[0]?.additionalNumberOfColors?.reduce(
        (a, b) => +a + +b,
        0,
      );
      totalNumberOfColors += +data.orders[0].numberOfColors;
    }
    setExtraCharges((prevState) => [
      ...prevState,
      {
        printForms: printFormsConditionExists ? 'DA' : 'NE',
        printFormsPrice: 0,
        printFormsAmount: totalNumberOfColors,
        toolsIsCharging: toolsIsChargingConditionExists ? 'DA' : 'NE',
        toolsIsChargingPrice: 0,
        toolsAmount: 0,
      },
    ]);

    ///////////////////////////////////////////////////////////////

    setOrderItems(items.data);
    let prices = [];

    for (let i = 0; i < items.data.length; i++) {
      prices.push(items.data[i].price);
    }

    setItemPrice(prices);

    let quantities = [];

    for (let i = 0; i < items.data.length; i++) {
      quantities.push(items.data[i].quantity);
    }

    setItemQuantity(quantities);
  };

  const createPdfHandler = async () => {
    for (let i = 0; i < orderItems.length; i++) {
      orderItems[i].price = itemPrice[i];
    }
    for (let i = 0; i < orderItems.length; i++) {
      orderItems[i].quantity = itemQuantity[i];
    }

    ///save order items with changed prices
    const save = await Axios.put(`${SERVER_URL}/orderconfirmation-items/${data._id}`, orderItems, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    ///kreiranje PDF-a
    const response = await Axios.post(
      `${SERVER_URL}/create-pdf-orderconfirmation`,
      {
        items: orderItems,
        extraCharges,
        eurCourse,
        isPriceInEuroActive,
        data,
        user: `${currentuser.data.firstName} ${currentuser.data.lastName}`,
      },
      {
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${currentuser.data.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    if (response.data.filename) {
      const pdfResponse = await Axios.get(`${SERVER_URL}/get-pdf?filename=${response.data.filename}`, {
        responseType: 'arraybuffer',
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      const pathArr = response.data.filename.split('\\');
      const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });
      let a = document.createElement('a');
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = pathArr[pathArr.length - 1];
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  const handleSendEmail = async () => {
    for (let i = 0; i < orderItems.length; i++) {
      orderItems[i].price = itemPrice[i];
    }
    for (let i = 0; i < orderItems.length; i++) {
      orderItems[i].quantity = itemQuantity[i];
    }

    ///save order items with changed prices
    const save = await Axios.put(`${SERVER_URL}/orderconfirmation-items/${data._id}`, orderItems, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });
    /////////////////////stampanje pdfa/////////////
    const response = await Axios.post(
      `${SERVER_URL}/create-pdf-orderconfirmation`,
      {
        items: orderItems,
        extraCharges,
        eurCourse,
        isPriceInEuroActive,
        data,
        user: `${currentuser.data.firstName} ${currentuser.data.lastName}`,
      },
      {
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${currentuser.data.token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    if (response.data.filename) {
      const pdfResponse = await Axios.get(`${SERVER_URL}/get-pdf?filename=${response.data.filename}`, {
        responseType: 'arraybuffer',
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      const pathArr = response.data.filename.split('\\');
      const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });
      let a = document.createElement('a');
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = pathArr[pathArr.length - 1];
      a.click();
      window.URL.revokeObjectURL(url);
    }
    //////////////////////////////////////////////////////////

    ////////slanje mejla
    const res = await Axios.post(
      `${SERVER_URL}/orderconfirmation-sendEmail`,
      {
        filename: response.data.filename,
        filepath: response.data.response,
        items: orderItems,
        data,
        emailValue,
        user: currentuser.data,
      },
      {
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${currentuser.data.token}`,
          'Content-Type': 'application/json',
        },
      },
    );

    notification.success({
      message: 'Uspešno poslata potvrda porudžbenice',
      placement: 'BottomRight',
    });
  };

  const onChangePrice = (value, indexToUpdate) => {
    setItemPrice((prevState) => prevState.map((item, index) => (index === indexToUpdate ? parseFloat(value) : item)));
  };

  const onChangeQuantity = (value, indexToUpdate) => {
    setItemQuantity((prevState) =>
      prevState.map((item, index) => (index === indexToUpdate ? parseFloat(value) : item)),
    );
  };

  const columns = [
    {
      title: 'Šifra proizvoda',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Naziv proizvoda',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Količina',
      key: 'quantity',
      render: (record) => {
        return (
          <InputNumber
            value={itemQuantity[record.index]}
            type='number'
            min='0'
            onChange={(e) => onChangeQuantity(e, record.index)}
          ></InputNumber>
        );
      },
    },
    {
      title: 'Cena (bez PDV) u rsd/jm',
      key: 'price',
      render: (record) => (
        <InputNumber
          value={itemPrice[record.index]}
          type='number'
          min='0'
          onChange={(e) => onChangePrice(e, record.index)}
        ></InputNumber>
      ),
    },
    {
      title: 'Ukupno u RSD bez PDV-a',
      render: (record) => {
        const res = (itemQuantity[record.index] * itemPrice[record.index]).toFixed(2);

        if (isNaN(res)) {
          return 0;
        } else {
          return res;
        }
      },
    },
  ];

  const extraCostsChangeHandler = (value, key, id) => {
    setExtraCharges((prevState) =>
      prevState.map((item) => ({
        ...item,
        [key]: value,
      })),
    );
  };

  const columnsExtraCharges = [
    {
      title: 'Naplata štamparskih formi',
      dataIndex: 'printForms',
      key: 'printForms',
    },
    {
      title: 'Cena štamparskih formi',
      key: 'print-price',
      render: (record) => {
        return (
          <InputNumber
            value={extraCharges['printFormsPrice']}
            type='number'
            disabled={record.printForms === 'NE'}
            min='0'
            onChange={(e) => extraCostsChangeHandler(e, 'printFormsPrice')}
          ></InputNumber>
        );
      },
    },

    {
      title: 'Štanc alat',
      dataIndex: 'toolsIsCharging',
      key: 'toolsIsCharging',
    },
    {
      title: 'Cena Alata(bez PDV) u rsd/jm',
      key: 'tool-price',
      render: (record) => (
        <InputNumber
          value={extraCharges['toolsIsChargingPrice']}
          disabled={record.toolsIsCharging === 'NE'}
          type='number'
          min='0'
          onChange={(e) => extraCostsChangeHandler(e, 'toolsIsChargingPrice')}
        ></InputNumber>
      ),
    },
  ];

  if (extraCharges.length > 0 && extraCharges[0]['printForms'] === 'DA') {
    columnsExtraCharges.splice(1, 0, {
      title: 'Količina štamparskih formi',
      dataIndex: 'printQuantity',
      key: 'printQuantity',
      render: (record) => (
        <InputNumber
          type='number'
          min='1'
          value={extraCharges[0]['printFormsAmount']}
          onChange={(e) => extraCostsChangeHandler(e, 'printFormsAmount')}
        ></InputNumber>
      ),
    });
  }

  if (extraCharges.length > 0 && extraCharges[0]['toolsIsCharging'] === 'DA') {
    columnsExtraCharges.splice(-1, 0, {
      title: 'Količina alata',
      dataIndex: 'toolQuantity',
      key: 'toolQuantity',
      render: (record) => (
        <InputNumber
          type='number'
          min='1'
          value={extraCharges[0]['toolsAmount']}
          onChange={(e) => extraCostsChangeHandler(e, 'toolsAmount')}
        ></InputNumber>
      ),
    });
  }

  const handleShowModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChangeEmailValue = (event, index) => {
    event.persist();

    let arr = emailValue;

    arr[index] = event.target.value;

    setEmailValue(arr);
    setReRender(!reRender);
  };

  return (
    <div className='panel panel-body '>
      <div className='panel-body'>
        <Card className='workOrderForm ' title={`PREGLED POTVRDE PORUDŽBINE`}>
          <Form {...layout} initialValues={initialValues} layout='vertical' form={form}>
            <Form.Item
              required
              name='ordernumber'
              className='ant-form-item-control-input-content'
              label='Broj potvrde porudžbine:'
            >
              <Input disabled />
            </Form.Item>

            <Form.Item className='ant-form-item-control-input-content' label='Nalozi proizvodnji da proizvede:'>
              <TextArea
                rows={ordersList?.length}
                style={{ minWidth: '900px' }}
                value={ordersList.join('\n')}
                disabled
              />
            </Form.Item>
            <Form.Item required name='date' label='Datum potvrde:'>
              <Input disabled />
            </Form.Item>

            <Form.Item required name='commercialist' label='Komercijalista:'>
              <Input disabled />
            </Form.Item>

            <Form.Item required name='expectedDeliveryDate' label='Očekivani datum isporuke(najkasnije do):'>
              <Input disabled />
            </Form.Item>

            <Form.Item required name='deliveryWay' label='Način isporuke:'>
              <Input disabled />
            </Form.Item>

            <Form.Item required name='deliveryAddress' label='Adresa isporuke:'>
              <Input disabled />
            </Form.Item>

            <Form.Item required name='contactDeliveryPerson' label='Kontakt osoba za isporuku:'>
              <Input disabled />
            </Form.Item>

            <Form.Item required name='contactPerson' label='Kontakt osoba za račun:'>
              <Input disabled />
            </Form.Item>

            <Form.Item required name='email' label='Mejl adresa za slanje e-fakture:'>
              <Input disabled />
            </Form.Item>

            <Form.Item required name='POnumber' label='PO broj(obavezno navesti na fakturi):'>
              <Input disabled />
            </Form.Item>

            <Form.Item required name='currency' label='Valuta:'>
              <Input disabled />
            </Form.Item>

            <div style={{ width: '100%' }} className='subtitle'>
              Proizvodi u potvrdi porudžbine
            </div>

            <Table
              columns={newColumns && newColumns.length > 0 ? newColumns : columns}
              dataSource={orderItems}
              pagination={false}
            ></Table>
            <Table columns={columnsExtraCharges} dataSource={extraCharges} pagination={false}></Table>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div>Ukupno {+totalPrice?.toFixed(2)} RSD</div>
            </div>
            {isPriceInEuroActive && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div>Ukupno {+(totalPrice / eurCourse).toFixed(2)} €</div>
              </div>
            )}

            <div className='text-right'>
              <Checkbox onChange={(e) => setisPriceInEuroActive(e.target.checked)}>Pošalji cenu u EUR</Checkbox>
              {isPriceInEuroActive && (
                <>
                  <label>Kurs:</label>
                  <InputNumber
                    min={1}
                    type='number'
                    value={eurCourse}
                    onChange={(e) => setEurCourse(e)}
                    style={{ margin: '0rem 1rem 0rem 0.5rem' }}
                  />
                </>
              )}
              <Button type='primary' onClick={handleShowModal} style={{ marginRight: '10px' }}>
                Pošalji potvrdu putem email-a
              </Button>
              <Button type='primary' onClick={createPdfHandler}>
                Odštampaj PDF
              </Button>
            </div>
          </Form>
        </Card>
      </div>
      <Modal
        style={{ top: '20%' }}
        open={isModalVisible}
        title='SLANJE POTVRDE NA MEJL'
        onCancel={handleCancel}
        footer={[]}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {mailsArr && mailsArr.length < 10 && (
            <Button
              onClick={() => {
                setMailsArr([...mailsArr, {}]);
              }}
              style={{ marginBottom: '20px' }}
              icon={<PlusSquareOutlined />}
            >
              Dodaj novi mejl
            </Button>
          )}
          {mailsArr &&
            mailsArr.length > 0 &&
            mailsArr.map((item, index) => {
              return (
                <Input
                  style={{ margin: '5px' }}
                  value={emailValue[index]}
                  onChange={(e) => {
                    onChangeEmailValue(e, index);
                  }}
                />
              );
            })}

          <Button type='primary' onClick={handleSendEmail}>
            Pošalji potvrdu putem email-a
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default OrderConfirmationViewForm;
