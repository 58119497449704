import React, { useState, useContext, useEffect } from 'react';
import { Divider, Input, Select, Button, Form, Card, Table, Popconfirm, notification, InputNumber } from 'antd';
import { SERVER_URL } from '../../config';
import Axios from 'axios';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { DeleteOutlined, EditOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Option } = Select;

const formInit = {
  _id: null,
  name: undefined,
  code: '',
  calculationMethod: '',
  width: 1,
  rolls: [
    {
      weight: '',
      length: '',
    },
  ],
  status: 'ACTIVE',
};

const MaterialForm = ({ isNew, data, onSubmit, materials, rolls }) => {
  const [form] = Form.useForm();
  const [calculationType, setCalculationType] = useState('');
  const [inputVisible, setInputVisible] = useState(false);
  const [rollNumber, setRollNumber] = useState([]);
  const currentuser = useContext(UserContext);
  const [rollsReserved, fetchRollsReserved] = useAxios('', [], currentuser.data.token, 'get');
  const [isDisabled, setIsDisabled] = useState(true);
  const [tableDataState, setTableDataState] = useState();

  const [selectedRows, setSelectedRows] = useState([]);
  let initialValues = { ...formInit, ...{ ...data, rolls } };
  let searchInput;

  const onFinish = async (values, isNew) => {
    onSubmit(values, isNew);
  };
  useEffect(() => {
    if (data && data._id) {
      let filter = { material: data._id };
      fetchRollsReserved(`${SERVER_URL}/rolls-reserved?filter=${JSON.stringify(filter)}`);
    }
  }, [fetchRollsReserved]);

  const addRoll = () => {
    setInputVisible(true);
    setRollNumber([...rollNumber, {}]);
  };

  const removeRoll = () => {
    const result = [...rollNumber].filter((item, index) => index !== rollNumber.length - 1);
    setRollNumber(result);
  };

  const calculationMethod = [
    {
      name: 'Za m2',
      formula: 'd*s/1000',
    },
    {
      name: 'Za kg',
      formula: 't*1000/p/s*1000',
    },
  ];
  // const verifyMaterial = async () => {
  //   if (form.getFieldValue('materialIDVerify') && form.getFieldValue('materialIDVerify') === data.materialID) {
  //     await Axios.put(`${SERVER_URL}/materials/${data._id}`, { status: 'verified' }, {
  //       withCredentials: false,
  //       headers: { Authorization: `Bearer ${currentuser.data.token}` },
  //     });
  //     notification.success({
  //       message: `Materijal je verifikovan.`,
  //       placement: 'bottomRight',
  //     });
  //   } else {
  //     notification.error({
  //       message: `Greška prilikom verifikacije materijala.`,
  //       placement: 'bottomRight',
  //     });
  //   }
  // };

  const deleteRoll = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/rolls/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Rolna je obrisana.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      let filter = localStorage.getItem('filter') && JSON.parse(localStorage.getItem('filter'));
      if (filter) {
        for (const property in filter) {
          if (dataIndex === property) {
            if (Array.isArray(selectedKeys) && selectedKeys.length === 0 && selectedKeys[0] !== filter[property]) {
              setSelectedKeys([`${filter[property]}`]);
              confirm();
            }
          }
        }
      }
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : '')}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex, setSelectedKeys)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      let searchFilter = [];
      searchFilter = value.split(' ');
      const filterRecord = Array.isArray(dataIndex)
        ? record[dataIndex[0]][dataIndex[1]]?.toLowerCase()
        : record[dataIndex]?.toLowerCase();
      if (!filterRecord || !searchFilter) {
        return false;
      }
      return searchFilter.every((value) => filterRecord.includes(value.toLowerCase()));
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    let filter = localStorage.getItem('filter') ? JSON.parse(localStorage.getItem('filter')) : {};
    filter[`${dataIndex}`] = selectedKeys[0];
    localStorage.setItem('filter', JSON.stringify(filter));
    confirm();
  };

  const handleReset = (clearFilters, dataIndex, setSelectedKeys) => {
    let filter = localStorage.getItem('filter') && JSON.parse(localStorage.getItem('filter'));
    if (filter) {
      for (const property in filter) {
        if (dataIndex === property) {
          setSelectedKeys([]);
          delete filter[property];
          clearFilters();
        }
      }
    }
    localStorage.setItem('filter', JSON.stringify(filter));
  };

  const columns = [
    {
      title: 'ID rolne',
      dataIndex: 'rollID',
      key: 'rollID',
    },
    {
      title: 'QR kod',
      dataIndex: 'QRCode',
      key: 'QRCode',
      width: '3%',
      render: (QRCode) => <img className='qrCodeInTable' src={QRCode ? QRCode : ''} />,
    },
    {
      title: 'Datum unosa',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '15%',
    },
    {
      title: 'Status i pozicija u magacinu',
      render: (record, text) =>
        `${record.status}  ${
          record.status?.includes('magacin') ? (record.storagePosition ? `- ${record?.storagePosition}` : '') : ''
        }`,
      ...getColumnSearchProps('status'),
    },
    {
      title: 'Količina (kg/m2)',
      render: (record, text) => parseFloat(record.state).toFixed(2),
    },
    {
      title: 'Opcije',
      key: 'action',
      render: (text, record) => (
        <div>
          <Link to={'/admin/view-roll/' + record._id} style={{ marginRight: '10px' }}>
            <EyeOutlined title={'Pregled rolne'} style={{ textDecoration: 'none', color: 'black' }} />
          </Link>

          {record.status === 'U magacinu' && (
            <Link to={'/admin/edit-roll/' + record._id} style={{ marginRight: '10px' }}>
              <EditOutlined title={'Izmeni rolnu'} style={{ textDecoration: 'none', color: 'black' }} />
            </Link>
          )}
          {record.status === 'U magacinu' && record.initialWeight === record.weight && (
            <Popconfirm
              placement='left'
              title={`Ovo će obrisati rolnu`}
              onConfirm={() => {
                deleteRoll(record._id);
              }}
              okText='Ok'
              cancelText='Cancel'
            >
              <DeleteOutlined title={`Obriši rolnu`} />
            </Popconfirm>
          )}
        </div>
      ),
    },
  ];
  let tableData = [];
  let workOrderCodes = [];
  if (rolls) {
    tableData = rolls.map((roll) => {
      workOrderCodes = [];
      if (rollsReserved && rollsReserved.data && rollsReserved.data?.items) {
        let rollReserved = rollsReserved.data.items?.filter((item) => item.roll?._id === roll?._id);
        for (const item of rollReserved) {
          workOrderCodes.push(item.workOrder?.code);
        }
      }
      roll.createdAt = new Date(roll.createdAt).toLocaleString();
      roll.code = workOrderCodes.length <= 1 ? workOrderCodes.toString() : workOrderCodes.join(', ');
      return roll;
    });
  }

  useEffect(() => {
    sortTableData();
  }, [tableData.length < 1]);

  const sortTableData = () => {
    let storageArr = [];
    let prodArr = [];
    let spentArr = [];
    let verifyFromProdArr = [];
    let verifyFromStorArr = [];

    if (tableData.length > 0) {
      for (let i = 0; i < tableData.length; i++) {
        if (tableData[i].status === 'U magacinu') {
          storageArr.push(tableData[i]);
        } else if (tableData[i].status === 'U proizvodnji') {
          prodArr.push(tableData[i]);
        } else if (tableData[i].status === 'Čeka verifikaciju(iz proizvodnje)') {
          verifyFromProdArr.push(tableData[i]);
        } else if (tableData[i].status === 'Potrošena') {
          spentArr.push(tableData[i]);
        } else if (tableData[i].status === 'Čeka verifikaciju(iz magacina)') {
          verifyFromStorArr.push(tableData[i]);
        }
      }

      tableData = [];
      tableData.push(...verifyFromProdArr);
      tableData.push(...verifyFromStorArr);
      tableData.push(...storageArr);
      tableData.push(...prodArr);
      if (!currentuser.data.role.includes('storekeeper')) {
        tableData.push(...spentArr);
      }
      setTableDataState(tableData);
    }
  };

  const pdfButton = (selectedRows) => {
    if (selectedRows.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
    setSelectedRows(selectedRows);
  };

  const generatePdf = async () => {
    const response = await Axios.post(
      `${SERVER_URL}/generate-pdf`,
      { selectedRows },
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}`, 'Content-Type': 'application/json' },
      },
    );
    if (response.data.filename) {
      const pdfResponse = await Axios.get(`${SERVER_URL}/get-material-pdf?filename=${response.data.filename}`, {
        responseType: 'arraybuffer',
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      const pathArr = response.data.filename.split('\\');
      const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });
      let a = document.createElement('a');
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = pathArr[pathArr.length - 1];
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      pdfButton(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.status === 'replaced',
    }),
  };

  function onChange(page) {
    page && localStorage.setItem('pageNumber', page);
    localStorage.getItem('pageNumber') &&
      parseInt(localStorage.getItem('pageNumber')) === 1 &&
      localStorage.removeItem('pageNumber');
  }

  if (localStorage.getItem('url') && localStorage.getItem('url') !== window.location.href) {
    localStorage.removeItem('pageNumber');
    localStorage.removeItem('filter');
    localStorage.setItem('url', window.location.href);
  } else {
    localStorage.setItem('url', window.location.href);
  }

  return (
    <div className={isNew ? 'dashboard' : 'panel panel-body'}>
      <div className={isNew ? 'card-wrapper' : 'panel-body'}>
        <Card title={`${isNew ? 'NOVI MATERIJAL' : 'IZMENA MATERIJALA'}`} bordered={false}>
          <Form
            className='form-horizontal'
            initialValues={initialValues}
            onFinish={(values) => onFinish(values, !data)}
            layout='horizontal'
            form={form}
          >
            <Form.Item
              label='Naziv materijala'
              rules={[{ required: true, message: 'Molimo Vas upišite naziv materijala!' }]}
              name='name'
              style={{ marginLeft: '43px' }}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label='Šifra'
              name='code'
              rules={[{ required: true, message: 'Molimo Vas upišite šifru materijala!' }]}
              style={{ marginLeft: '79px' }}
            >
              <Input />
            </Form.Item>
            {isNew && (
              <Form.Item label='Postojeće šifre' style={{ marginLeft: '65px' }}>
                <Select
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option.props.children.join().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {materials.items &&
                    materials.items.length > 0 &&
                    materials.items.map((item, index) => (
                      <Option key={index}>
                        {item.code} - {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              label='Širina (u mm)'
              name='width'
              style={{ marginLeft: '61px' }}
              rules={[
                {
                  validator: (rule, value) => {
                    if (value) {
                      return value <= 0 ? Promise.reject('Širina ne sme biti 0 ili manje od 0') : Promise.resolve();
                    }
                    return Promise.resolve();
                  },
                },
                { required: true },
              ]}
            >
              <Input type='number' />
            </Form.Item>

            <Form.Item
              label='Specifična težina (u g)'
              style={
                data?.calculationMethod === 't*1000/p/s*1000' || calculationType === 't*1000/p/s*1000'
                  ? { marginLeft: '2px' }
                  : { marginLeft: '13px' }
              }
              name='specificWeight'
              required={data?.calculationMethod === 't*1000/p/s*1000' || calculationType === 't*1000/p/s*1000'}
            >
              <Input
                disabled={
                  calculationType === 'd*s/1000' ||
                  (data?.calculationMethod !== 't*1000/p/s*1000' && calculationType !== 't*1000/p/s*1000')
                }
                type='number'
              />
            </Form.Item>
            <Form.Item
              label='Izaberite formulu'
              name='calculationMethod'
              style={{ marginLeft: '35px' }}
              rules={[{ required: true, whitespace: true, message: 'Molimo Vas izaberite način proračuna!' }]}
            >
              <Select
                disabled={!data?.calculationMethod ? false : true}
                onChange={(value) => setCalculationType(value)}
              >
                {calculationMethod.map((item, index) => (
                  <Option value={item.formula} key={index}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item required label='Min. količina (m)' name='minQuantity' style={{ marginLeft: '39px' }}>
              <Input type='number' min='1'></Input>
            </Form.Item>
            <div className='text-right' style={{ marginBottom: '10px' }}>
              {inputVisible && rollNumber.length > 0 && (
                <Button onClick={removeRoll} style={{ marginRight: '10px' }} type='primary'>
                  Ukloni
                </Button>
              )}
              {rollNumber.length < 1 && (
                <Button
                  disabled={
                    !data?.calculationMethod ||
                    (data?.calculationMethod === 't*1000/p/s*1000' && (!data?.width || !data?.specificWeight)) ||
                    (data?.calculationMethod === 'd*s/1000' && !data?.width)
                  }
                  onClick={addRoll}
                  style={{ marginRight: '10px' }}
                  type='primary'
                  htmlType='button'
                >
                  Dodaj rolnu
                </Button>
              )}
              <Button type='primary' htmlType='submit'>
                {isNew ? 'Dodaj materijal' : 'Sačuvaj promene'}
              </Button>
            </div>
            {inputVisible &&
              rollNumber?.length >= 0 &&
              rollNumber.map((item, index) => (
                <div key={index}>
                  <Divider />
                  <Form.Item
                    label='Težina (u kg)'
                    name={['rolls', index, 'weight']}
                    rules={[{ required: true, whitespace: true, message: 'Molimo Vas unesite težinu!' }]}
                  >
                    <Input min={0} type='number' step="0.1" />
                  </Form.Item>
                  <Form.Item label='Pozicija u magacinu' name={['rolls', index, 'storagePosition']}>
                    <Input />
                  </Form.Item>
                  {(calculationType === 'd*s/1000' ||
                    (data?.calculationMethod !== 't*1000/p/s*1000' && calculationType !== 't*1000/p/s*1000')) && (
                    <div>
                      <Form.Item
                        label='Dužina (u m)'
                        name={['rolls', index, 'length']}
                        rules={[{ required: true, whitespace: true, message: 'Molimo Vas unesite dužinu!' }]}
                      >
                        <Input min={0} type='number' />
                      </Form.Item>
                    </div>
                  )}
                </div>
              ))}

            {!isNew && rolls?.length > 0 && (
              <div>
                <div className='text-right' style={{ marginBottom: '10px' }}>
                  <Button disabled={isDisabled} style={{ marginLeft: '10px' }} onClick={generatePdf} type='primary'>
                    PDF
                  </Button>
                </div>
                <Form.Item>
                  <Table
                    size='middle'
                    bordered
                    dataSource={tableDataState}
                    columns={columns}
                    rowKey='_id'
                    pagination={{
                      defaultPageSize: 50,
                      position: 'bottom',
                      showSizeChanger: false,
                      pageSizeOptions: ['10', '20', '50', '100'],
                      hideOnSinglePage: true,
                      onChange: onChange,
                      defaultCurrent: localStorage.getItem('pageNumber')
                        ? parseInt(localStorage.getItem('pageNumber'))
                        : 1,
                    }}
                    rowSelection={rowSelection}
                  />

                  {/*<Select>
                  {rolls.map((item, index) => {
                    if (data.calculationMethod === 'd*s') {
                      return (
                        <Option key={index}> 1 X {item.length?.toFixed(2)} ({item.state.toFixed(2)}m2 - {item.weight.toFixed(2)}kg)</Option>
                      );
                    }
                    return (
                      <Option key={index}> 1 X {item.length?.toFixed(2)} ({item.weight.toFixed(2)}kg)</Option>
                    );
                  })}
                </Select>*/}
                </Form.Item>
              </div>
            )}
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default MaterialForm;
